<template>
  <div>
    <v-row :class="`${$route.path != '/contato' ? 'footer d-flex pt-10' : 'footer-small-margin d-flex  pt-10'}`">

      <v-col cols="12" lg="2" md="12" sm="12" xs="12" class="d-flex flex-column justify-space-between">
        <v-btn text color="#F66844" class="text-none mb-5 apoio-title">Patrocínio</v-btn>
        <v-img src="../assets/senac.png" height="50" max-height="50" contain class="mb-5"></v-img>
        <v-img src="../assets/transfero.png" height="50" max-height="50" contain class="mb-5"></v-img>
      </v-col>

      <hr class="line-divider divider-senac">

      <v-col cols="12" lg="9" md="12" sm="12" xs="12" class="d-flex flex-column">
        <v-btn text color="#F66844" class="text-none text-left apoio-title">Apoio</v-btn>

        <v-row class="d-flex justify-center align-center">
          <v-col cols="6" lg="2" md="2" sm="2" xs="6" class="d-flex flex-column align-center justify-space-between">
            <v-img class="logo mb-5" src="../assets/joco.png" width="50" contain></v-img>
            <v-img class="logo mb-5" src="../assets/rockinrio.png" width="100" contain></v-img>
          </v-col>

          <v-col cols="6" lg="2" md="2" sm="2" xs="6" class="d-flex flex-column align-center justify-space-between">
            <v-img class="logo mb-5" src="../assets/globo.png" width="100" contain></v-img>
            <v-img class="logo mb-5" src="../assets/tmrw.png" width="100" contain></v-img>
          </v-col>

          <v-col cols="6" lg="2" md="2" sm="2" xs="6" class="d-flex flex-column align-center justify-space-between">
            <v-img class="logo mb-5" src="../assets/villagemall.png" width="150" contain></v-img>
            <v-img class="logo mb-5" src="../assets/associacaoportas.png" width="120" contain></v-img>
          </v-col>

          <v-col cols="6" lg="2" md="2" sm="2" xs="6" class="d-flex flex-column align-center justify-space-between">
            <v-img class="logo mb-5" src="../assets/spoleto.png" width="100" contain></v-img>
            <v-img class="logo mb-5" src="../assets/square.png" width="100" contain></v-img>
          </v-col>

          <v-col cols="6" lg="2" md="2" sm="2" xs="6" class="d-flex flex-column align-center justify-space-between">
            <v-img class="logo mb-5" src="../assets/more.png" width="80" contain></v-img>
            <v-img class="logo mb-5" src="../assets/binder.png" width="100" contain></v-img>
          </v-col>

          <v-col cols="6" lg="2" md="2" sm="2" xs="6" class="d-flex flex-column align-center justify-space-between">
            <v-img class="logo mb-5" src="../assets/provoke.png" width="80" contain></v-img>
            <v-img class="logo mb-5" src="../assets/hsm.png" width="80" contain></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="footer d-flex pt-10 mt-0" style="background-color: #1C155C;">
      <v-col cols="12" lg="4" md="5" sm="6" xs="6" class="d-flex flex-column links_footer">
        <v-btn text color="#F66844" class="text-none" style="font-size: 18px; font-weight: bold;" link to="/">O
          Movimento</v-btn>
          <v-btn text color="#FFFFFF" class="text-none"link to="/ganhadores">Ganhadores</v-btn>
        <v-btn text color="#FFFFFF" class="text-none" link to="/premios">Prêmio</v-btn>
        <v-btn text color="#FFFFFF" class="text-none" link to="/ranking">Ranking</v-btn>
        <v-btn text color="#FFFFFF" class="text-none" link to="/escolas-participantes">Escolas e
          instituições participantes</v-btn>
        <v-btn text color="#FFFFFF" class="text-none" link
          href="https://static-joco.sfo2.cdn.digitaloceanspaces.com/nossojoco/pdfs/1717762909221.pdf"
          target="_blank">Regulamento</v-btn>
        <v-btn text color="#FFFFFF" class="text-none" link to="/faq">FAQ</v-btn>
        <v-btn text color="#FFFFFF" class="text-none" link to="/contato">Contato</v-btn>
      </v-col>

      <hr class="line-divider">

      <v-col cols="12" lg="4" md="4" sm="4" xs="6" class="d-flex flex-column links_footer senac">
        <v-btn text color="#F66844" class="text-none" style="font-size: 18px; font-weight: bold;"
          href="https://www.rj.senac.br/">Senac RJ</v-btn>
        <v-btn text color="#FFFFFF" class="text-none" href="https://www.rj.senac.br/unidades/">Unidades</v-btn>
        <v-btn text color="#FFFFFF" class="text-none" href="http://www.rj.senac.br/senac-tecnologia">Cursos</v-btn>
        <v-btn text color="#FFFFFF" class="text-none"
          href="https://www.rj.senac.br/sobre-o-senac/responsabilidade-social/">Responsabilidade Social</v-btn>
        <div class="social-media ml-4">
          <v-btn icon color="#FFFFFF" width="25" href="https://www.youtube.com/senacrj"> <v-img
              src="../assets/youtube.png" height="25" contain></v-img> </v-btn>
          <v-btn icon color="#FFFFFF" width="25" class="ml-3" href="https://www.instagram.com/Senac_RJ/"> <v-img
              src="../assets/instagram.png" height="25" contain></v-img> </v-btn>
          <v-btn icon color="#FFFFFF" width="25" class="ml-2" href="https://www.facebook.com/senacrj"> <v-img
              src="../assets/facebook.png" height="25" contain></v-img> </v-btn>
          <v-btn icon color="#FFFFFF" width="25" class="ml-2" href="https://www.linkedin.com/school/senac-rj/"> <v-img
              src="../assets/linkedin.png" height="25" contain></v-img> </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="footer_end_wrap d-flex justify-center align-center pt-2" style="background-color: #1C155C;">
      <p class="mb-0" style="font-size: 14px; font-weight: 300;">© 2024 <a
          style="text-decoration: none; color: #FFFFFF;" href="https://www.joco.com.br/">Joco</a></p>
      <v-img src="../assets/door.png" class="door-img hide_mobile" contain></v-img>

    </v-row>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: ["margin"],
};


</script>

<style scoped>
.footer {
  background-color: #1C155C;
  padding-left: 100px;
}

.apoio-title {
  font-size: 18px;
  font-weight: bold;
  align-self: flex-start;
}

.footer-small-margin {
  background-color: #1C155C;
}

.links_footer {
  align-items: start;
}

.logo {
  height: 60px;
}

.door-img {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.joco {
  justify-content: end;
}

hr.line-divider {
  height: 250px;
  border: 1px solid #433B87;
}

.col-apoio {
  margin-right: 100px;
}

@media screen and (max-width: 1263px) {
  .footer {
    padding-left: 0;
  }

  .apoio-title {
    align-self: center;
  }

  .footer_end_wrap {
    flex-direction: column-reverse;
  }

  .footer_end {
    justify-content: center;
  }

  .door-img {
    height: 350px;
    right: 10px;
  }

  .divider-senac {
    display: none;
  }

  .col-apoio {
    margin-right: 0;
  }
}

@media screen and (max-width: 959px) {

  .line-movimento {
    display: none;
  }

}

@media screen and (max-width: 600px) {

  .links_footer {
    align-items: center;
    padding-top: 100px;
  }

  hr.line-divider {
    display: none;
  }

  .senac {
    margin: 0;
    padding-top: 50px;
  }

  .footer {
    height: 750px;
    padding-top: 100px;

  }

  .footer_end {
    justify-content: center;
  }

}
</style>
