<template>
    <div class="page premios">

      <v-row class="hide_desktop">
        <v-col cols="12" class="mt-12 hide_desktop">
          <h1 class="hide_desktop">Prêmio</h1>
        </v-col>
      </v-row>

      <v-row >
        <v-col class="hide_mobile">
          <v-img src="../assets/plus.png" lg="1" md="1" class="hide_mobile" height="100" contain></v-img>
        </v-col>

        <v-col cols="12" lg="7" md="7" sm="12" class="pt-0">
          <h1 class="hide_mobile">Prêmio</h1>
          <p class="mt-4 premios-description">Todos os jogadores que se cadastrarem concorrem ao sorteio de um laptop!</p>
        </v-col>

        <v-col class="hide_mobile">
          <v-img src="../assets/double-triangle.png" lg="1" md="1" class="hide_mobile" height="100" contain></v-img>
        </v-col>

        <v-col cols="12" xl="5" lg="5" md="5" sm="5" class="hide_mobile">
            <v-img src="../assets/cloud.png" max-height="300" contain class="cloud_img"></v-img>
        </v-col>
      </v-row>

    <!--
      Para escolas e instituições

      <v-row class="premios-title">
        <v-col cols="12">
          <h1>Para Escolas e Instituições</h1>
        </v-col>
      </v-row>

      <div class="row-container">
      <v-row class="pa-4 cards-row">
        <v-col cols="12" lg="4" md="6" sm="6">
          <v-card height="265" width="275" class="pr-4 pl-4 pt-5 mx-auto">
            <v-img src="../assets/senac-colored.png" height="110" contain></v-img>
            <v-card-title class="pl-0 pr-0 pl-0 pr-0 card_title font-weight-bold">50 Cursos de Data Analytics e 50 de Excel do Senac</v-card-title>
            <v-card-text class="pl-0 pr-0 card_text" >(para cada Escola/Instituição participante)</v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="4" md="6" sm="6">
          <v-card height="265" width="275" class="pr-4 pl-4 pt-6 mx-auto">
            <v-img src="../assets/delete.png" width="200" height="110" contain class="mx-auto"></v-img>
            <v-card-title class="pl-0 pr-0 card_title font-weight-bold">Curso Tela e Saúde Mental do <br>Instituto Delete</v-card-title>
            <v-card-text class="pl-0 pr-0 card_text" >(para professores das Escolas/Instituições participantes)</v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="6">
          <v-card height="265" width="275" class="pr-4 pl-4 pt-5 mx-auto">
            <v-img src="../assets/spoleto.png" height="110" contain></v-img>
            <v-card-title class="pl-0 pr-0 card_title font-weight-bold">100 vouchers</v-card-title>
            <v-card-text class="pl-0 pr-0 card_text" >Distribuídos entre as Escolas/Instituições vencedoras <br> (as 3 primeiras do ranking)</v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3" md="6" sm="6">
          <v-card height="265" width="275" class="pr-4 pl-4 pt-5 mx-auto">
            <v-img src="../assets/rockinrio.png" height="125" contain></v-img>
            <v-card-title class="pl-0 pr-0 card_title font-weight-bold">3 pares de Ingressos RockinRio</v-card-title>
            <v-card-text class="pl-0 pr-0 card_text" >para Escolas/Instituições vencedoras (as 3 primeiras do ranking)</v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3" md="6" sm="6">
          <v-card height="265" width="275" class="pr-4 pl-4 pt-5 mx-auto">
            <v-img src="../assets/kit.png" height="110" contain></v-img>
            <v-card-title class="pl-0 pr-0 card_title font-weight-bold">3 kits de Caixas de Som e <br> projetores portáteis</v-card-title>
            <v-card-text class="pl-0 pr-0 card_text" >para Escolas/Instituições vencedoras (as 3 primeiras do ranking)</v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3" md="6" sm="6">
          <v-card height="265" width="275" class="pr-4 pl-4 pt-5 mx-auto">
            <v-img src="../assets/imersivo.png" height="110" contain></v-img>
            <v-card-title class="pl-0 pr-0 card_title font-weight-bold">Experiência Imersiva</v-card-title>
            <v-card-text class="pl-0 pr-0 card_text" >Realidade Virtual será levada para as Escolas vencedoras (as 3 primeiras do ranking)</v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3" md="6" sm="6">
          <v-card height="265" width="275" class="pr-4 pl-4 pt-5 mx-auto">
            <v-img src="../assets/marisamonte.png" height="110" contain></v-img>
            <v-card-title class="pl-0 pr-0 card_title font-weight-bold">Violão Autografado da <br> Marisa Monte</v-card-title>
            <v-card-text class="pl-0 pr-0 card_text" >Doação para a Escola/Instituição vencedora (a 1 do ranking)</v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    -->




      <!-- Para usuários/jogadores -->
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-card height="265" width="275" class="pr-4 pl-4 pt-5 mx-auto">
            <v-img src="../assets/notebook.png" height="125" contain></v-img>
            <v-card-title class="pl-0 pr-0 pl-0 pr-0 card_title font-weight-bold">Sorteio de 1 Laptop Gamer</v-card-title>
            <v-card-text class="pl-0 pr-0 card_text" >(todos os usuários participantes concorrem ao sorteio)</v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Footer -->
      <Footer></Footer>

    </div>
  </template>

  <script>
  import Footer from '../components/footer.vue'

  export default {
    name: 'PremiosView',
    components: {
      Footer
    }
  }
  </script>

  <style scoped>
  .premios {
    background-color: #5B268E;
    min-height: 100vh;
    color: white;
    background-image:
      radial-gradient(closest-corner circle at 80% 30%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
      radial-gradient(closest-corner circle at 10% 15%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
      radial-gradient(closest-corner circle at 5% 70%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%);
  }

  .premios-description {
    font-size: 18px;
  }

  .cloud_img {
    position: absolute;
    right: 0;
  }

  .row-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .cards-row {
    max-width: 1200px;
    margin: auto;
    left: 0;
    right: 0;
  }

  .card_title{
    margin-top: 10px;
    padding: 0;
    font-size: 15px;
    line-height: 24px;
  }

  .card_text {
    margin-top: 10px;
    font-size: 13px;
    line-height: 24px;
    color: #4D4D4D;
    text-align: center;
  }


  </style>
