<template>
    <div class="page ganhadores">

      <v-row class="hide_desktop">
        <v-col cols="12" class="mt-12 hide_desktop">
          <h1 class="hide_desktop">Ganhadores</h1>
        </v-col>
      </v-row>

      <v-row >
        <v-col cols="12" lg="12" md="12" sm="12" class="pt-0 ganhadores-textContainer mt-4 mb-4">
            <div class="text">
                <h1 class="hide_mobile">Ganhadores</h1>
                <p class="mt-6 text-left premios-description">Confira abaixo as <b>10 escolas ganhadoras</b> que demonstraram um comprometimento extraordinário com a inovação e o aprendizado digital!</p>
            </div>
        </v-col>
      </v-row>


       <!-- Jogador -->
      
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
            <div class="list-container">
            <h1 class="text-left ganhadores-title mb-4">Jogador Vencedor</h1>
            <div class="item-details mb-10 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/prize.png" height="150" contain></v-img>
                </div>

                <div class="ganhador-information">
                    <div class="mb-4"><b>Arthur</b></div>
                    <div class="mb-4">Colégio Estadual Missionário Mário Way</div>
                </div>

            </div>
        </div>
        </v-col>
      </v-row>
      
     

      <!-- Escolas e instituições -->
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
            <div class="list-containerEscolas">
            <h1 class="text-left ganhadores-title mb-4">Escolas e Instituições Vencedoras</h1>
            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/first.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>Colégio Estadual Professora Venina Correa Torres</b></div>
                    <div class="mb-4">Rua Elías Mitre, 21 - Nova Iguaçu - RJ</div>
                </div>
            </div>

            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/second.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>Escola Municipal Geo Félix Mielli Venerando</b></div>
                    <div class="mb-4">Rua Carlos Seixas s/n° - Rio de Janeiro - RJ</div>
                </div>
            </div>


            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/third.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>CIEP 165 - Brigadeiro Sérgio Carvalho</b></div>
                    <div class="mb-4">Estrada do Lameirão Pequeno,1 - Rio de Janeiro - RJ</div>
                </div>
            </div>

            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/four.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>CIEP Brizolão 397 - Paulo Pontes</b></div>
                    <div class="mb-4">Rua Tenente Pedro Gomes Martins, s/nº - São João de Meriti - RJ</div>
                </div>
            </div>

            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/five.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>Colégio Estadual Professora Vânia do Amaral Matias Edde</b></div>
                    <div class="mb-4">Rua Fernanda, 1055 - Rio de Janeiro - RJ</div>
                </div>
            </div>

            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/six.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>CIEP 435 - Hélio Pellegrino</b></div>
                    <div class="mb-4">Estrada Guandu do Sapê, s/nº - Rio de Janeiro - RJ</div>
                </div>
            </div>

            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/seven.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>Colégio Estadual Hebe Camargo</b></div>
                    <div class="mb-4">Rua Projetada 1, s/nº - Rio de Janeiro - RJ</div>
                </div>
            </div>

            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/eight.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>CIEP 468 - Olga Thurler Mendonça da Fonseca</b></div>
                    <div class="mb-4">Rua Celina Garcia da Fonseca - Natividade - RJ</div>
                </div>
            </div>

            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/nine.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>Colégio Estadual Doutor Sylvio Bastos Tavares</b></div>
                    <div class="mb-4">Rua Viveiros de Vasconcelos, 245 - Campos dos Goytacazes - RJ</div>
                </div>
            </div>

            <div class="item-details mb-4 ml-10 mr-10" cols="12">
                <div class="premio-img">
                    <v-img src="@/assets/ten.png"  height="150" contain></v-img>
                </div>
                <div class="ganhador-information">
                    <div class="mb-4"><b>
                        CIEP 223 - Olympio Marques dos Santos</b></div>
                    <div class="mb-4">Estrada da Posse, s/nº - Rio de Janeiro -RJ</div>
                </div>
            </div>

            <div class="button-container">
                <v-btn text color="#FFFFFF" class="text-none" style="text-decoration: underline;" link to="/ranking">Ver ranking completo ></v-btn>
            </div>

        </div>
        </v-col>
      </v-row>

      <!-- Footer -->
      <Footer></Footer>

    </div>
  </template>

  <script>
  import Footer from '../components/footer.vue'

  export default {
    name: 'GanhadoresView',
    components: {
      Footer
    }
  }
  </script>

  <style scoped>
  .ganhadores {
    background-color: #5B268E;
    min-height: 100vh;
    color: white;
    background-image:
      radial-gradient(closest-corner circle at 80% 30%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
      radial-gradient(closest-corner circle at 10% 15%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
      radial-gradient(closest-corner circle at 5% 70%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%);
  }

.ganhadores-textContainer {
    background-image: url("https://static-joco.sfo2.cdn.digitaloceanspaces.com/nossojoco/images/1696861901200.png");
    background-position: right top;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text {
    width: 45%;
}

.premios-description {
    font-size: 20px;
}


.list-container {
    background-image: url('https://static-joco.sfo2.cdn.digitaloceanspaces.com/nossojoco/images/1696862485510.png');
    background-position-x: right;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left !important;
}

.item-details {
    text-align: left;
    display: flex;
    align-items: center;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.22);
    width: 90%;
    max-width: 900px;
    animation: fadeIn 0.5s;
}

.ganhadores-title {
    width: 90%;
    max-width: 900px;
    font-size: 20px;
}

.premio-img {
    margin-right: 30px;
}
.ganhador-information {
    width: 60%;
}

.list-containerEscolas {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
}

.button-container {
    width: 90%;
    max-width: 900px;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width:764px) {
    .ganhadores-textContainer, .list-container{
        background-image: none;
    }

    .ganhadores-textContainer{
        height: 150px;
    }
    .text{
        width: 90%;
    }
    .item-details{
        flex-direction: column;
        justify-content: center;
    }

    .premio-img {
        margin-right: 0;
    }
    .ganhador-information{
        width: 100%;
        text-align: center;
        margin: 15px 0;
    }
}

  </style>
