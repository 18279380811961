<template>
    <div class="page escolas" v-if="loaded">
        <v-row class="hero justify-end">
            <v-col cols="12" class="pa-10">
                <h1 class="text-center font-weight-bold hero_title">Ranking de Escolas e Instituições</h1>
            </v-col>
        </v-row>

        <div class="list-container">
            <div class="flex-right mb-8">
                <v-text-field class="search-input" v-model="filter" label="Procure a escola aqui" outlined rounded dark
                    prepend-inner-icon="mdi-magnify" hide-details="auto"></v-text-field>
            </div>

            <div v-if="filteredItems.length > 0"
                :class="item.pos == 1 ? 'item-details ranking-first mb-6 ml-10 mr-10' : 'item-details mb-6 ml-10 mr-10'"
                cols="12" v-for="(item, index) in filteredItems" :key="index">
                <div class="ranking-container">
                    <div><b>{{ item.pos }}º</b></div>
                    <div><b>{{ item.escola }}</b></div>
                    <div><b>{{ item.cidade }}</b></div>
                    <div><b>{{ item.jogadores }} jogadores</b></div>
                    <div class="score"><b>{{ item.pontos }} pts</b></div>
                </div>
            </div>

            <div class="ma-10" v-if="filteredItems.length == 0">
                <h3>Não existem escolas cadastradas com esse nome. Por favor, tente um nome diferente.</h3>
            </div>
        </div>


        <Footer></Footer>

    </div>
</template>

<script>
import escolas from "@/lib/Escolas";
import Footer from '../components/footer.vue'
import { getRanking } from "@/lib/Ranking";

export default {
    name: 'Ranking',
    components: {
        Footer
    },
    data: () => ({
        escolas: [],
        ranking_data: { ranking: [] },
        filter: '',
        filtered: [],
        loaded: false
    }),

    async mounted() {

        const ranking = await getRanking()

        this.ranking_data = { ranking: ranking.data, updated: new Date().toISOString() }

        this.loaded = true;
        this.escolas = escolas;

        this.escolas.forEach(e => {
            const pontos = ranking.data.find(r => r._id.replace(/\s/g, '') == e.escola.replace(/\s/g, ''));

            if (pontos && pontos.total) {
                e.pontos = pontos.total;
            }
            else {
                e.pontos = 0;
            }

            if (pontos && pontos.participantes) {
                e.jogadores = pontos.participantes
            }
            else {
                e.jogadores = 0;
            }
        });

        this.escolas = this.escolas.sort((a, b) => b.pontos - a.pontos);

        this.escolas.forEach((e, index) => {
            e.pos = index + 1;
        });


        this.filtered = this.escolas;
        window.scrollTo(0, 0);
    },
    methods: {

    },

    computed: {
        filteredItems() {
            return this.filtered.filter(e => e.escola.toLocaleLowerCase().includes(this.filter.toLocaleLowerCase()));
        }
    },
}
</script>

<style scoped>
.escolas {
    background-color: #5B268E;
    min-height: 100vh;
    color: white;
    background-image:
        radial-gradient(closest-corner circle at 80% 30%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
        radial-gradient(closest-corner circle at 10% 15%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%),
        radial-gradient(closest-corner circle at 5% 70%, #8A1E8B 33.33%, rgba(138, 30, 139, 0.00) 100%);
}

.row-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.list-container {
    background-image: url('@/assets/group_1.png');
    background-position-y: calc(176px);
    background-position-x: right;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 600px;
}

.item-details {
    text-align: left;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.22);
    width: 90%;
    max-width: 1200px;
    animation: fadeIn 0.5s;
}

.search-input {
    background-color: rgba(255, 255, 255, 0.15);
    max-width: 350px;
}

.flex-right {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1200px;
    justify-content: flex-end;
}

.ranking-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.ranking-first {
    background-color: #F26F21;
    transform: scale(1.07);
    border-radius: 4px;
}

.score {
    font-size: 22px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10vh)
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media screen and (max-width: 1000px) {
    .list-container {
        background-image: none;
    }
}

@media screen and (max-width: 450px) {
    .ranking-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .item-details {
        text-align: center;
    }

}
</style>
